<template>
  <div>
    <Lang/>
  </div>
</template>

<script>
import Lang from '@/components/Lang.vue'

export default {
  name: "NewLang",
  components: {
    Lang,
  }
}
</script>

